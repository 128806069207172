<template>
<div class="detil">
  <HeadMobile/>
  <div class="detil-main">
    <div class="detil-main-weizhi">{{$store.state.staticName.position}}：
      <span @click="goHome">{{$store.state.staticName.home}}</span>
      >
      {{detilData.title}}
    </div>
    <div class="detil-main-titles">
      <div class="detil-main-title">{{detilData.title}}</div>
      <div class="detil-main-synopsis">{{detilData.synopsis}}</div>
    </div>
    <div class="detil-main-post" v-html="detilData.content">

    </div>
    <div class="detil-main-post-footer">
      <div @click="handlenNewBefore">{{$store.state.staticName.Last}}：{{newBefore.title}}</div>
      <div @click="handleNewAfter">{{$store.state.staticName.Next}}：{{newAfter.title}}</div>
    </div>
  </div>
  <FootMobile/>
</div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from 'vuex';
export default {
  name: "NewDetailsMobile",
  components:{
    HeadMobile,
    FootMobile
  },
  data(){
    return{
      id:0,
      newType: '',
      detilData:{},
      newAfter:'',
      newBefore:'',
    }
  },
  watch:{
    "$store.state.languageType": {
      handler(newVal) {
        this.getDetils();
      },
      // deep: true // 深度监听
    }
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    })
  },
  created() {
    this.id = this.$route.params.newId;
    this.newType = this.$route.params.newType;
    this.getDetils();
  },
  methods:{
    goHome(){
      this.$router.push({path:"/HomeMobile"})
    },
    handlenNewBefore(){
      if(this.detilData.newBefore.id!==0){
        this.id = this.detilData.newBefore.id;
        this.getDetils();
      }else{
        this.$message.warning(this.$store.state.languageType==='CHINESE'?"没有上一条数据":'No Last data');
      }
    },
    handleNewAfter(){
      if(this.detilData.newAfter.id!==0){
      this.id = this.detilData.newAfter.id;
      this.getDetils();
    }else{
      this.$message.warning(this.$store.state.languageType==='CHINESE'?"没有下一条数据":'No nest data');
    }
    },
    getDetils(){
      this.$axios({
        url:'/queryNewsDetail',
        method:'get',
        params:{
          type: this.languageType,
          newType:this.newType,
          id:this.id
        }
      }).then(res=>{
        this.detilData = res.data.value;
        this.newBefore = this.detilData.newBefore
        this.newAfter = this.detilData.newAfter
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detil{
  background-color: #fff;
  .detil-main {
    width: 375px;
    margin: 0 auto;
    .detil-main-weizhi {
      font-size: 10px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      span:hover {
        color: #1850AF;
        cursor: pointer;
      }
    }
    .detil-main-titles{
      width: 100%;
      margin-top: 8px;
      .detil-main-title{
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
      .detil-main-synopsis{
        margin-top: 2px;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }
    }
    .detil-main-post{
      font-size: 12px;
      margin-top: 14px;
      background: #f7f7f7;
      padding: {
        top: 13px;
        bottom: 2px;
      };
      margin: {
        left: 11px;
        right: 11px;
      };
    }
    .detil-main-post-footer{
      margin: {
        top: 8px;
        bottom: 9px;
        left: 11px;
        right: 11px;
      };
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      font-weight: 400;
      text-align: left;
      color: #4d4d4d;
      div{
        cursor: pointer;
      }
    }
  }
}
</style>